import "../styles/main.css";

export default function Header() {
    return (
        <div className="top-banner">
            <h1>osu!droid PP Project Testing Ground</h1>
            <p>PP development website for osu!droid.</p>
        </div>
    );
}
