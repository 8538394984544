export enum PlayListSortMode {
    liveAscending,
    liveDescending,
    localAscending,
    localDescending,
    diffAscending,
    diffDescending,
    rateAscending,
    rateDescending,
    accuracyAscending,
    accuracyDescending,
    comboAscending,
    comboDescending,
    hit100Ascending,
    hit100Descending,
    hit50Ascending,
    hit50Descending,
    missAscending,
    missDescending,
}
